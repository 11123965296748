import { useState, useEffect } from 'react';
import axios from 'axios';
import LeaderRow from './LeaderRow';

export default function Leaderboard(props) {

  const [leaderboardData, setLeaderboardData] = useState(null);

  useEffect(() => {

    const url = "https://api.puttr.co/club/leaderboard/" + props.clubId + "/" + props.gameId + "/0";

    axios.get(url)
      .then(res => {
        setLeaderboardData(res.data);
      })

    return () => {

    };
  }, []);

  if (leaderboardData){

    if (leaderboardData.length == 0){
      return(
        <div>

          <h3>NO SCORES YET!</h3>

        </div>
      );
    }

    return leaderboardData.map((leader, index) => {

      return(

        <div key={index}>

          <LeaderRow leader={leader} place={index+1} />

        </div>
      );

    });

  }

  return(

    <div>

      <h3>LOADING LEADRERBOARD</h3>

    </div>
  );
}
