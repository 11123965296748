import './GameScreen.css';
import Leaderboard from '../leaderboard/Leaderboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Tees from '../common/Tees';
import Scoreboard from '../common/Scoreboard';

export default function Standard(props) {

  const screenWidth = window.innerWidth-400;
  const renderGameDetails = () => {

    const game = props.game.currentGame;

    return(

      <div style={{alignItems:'center', justifyContent:'center'}}>

        <div className="gameIcon">

          <FontAwesomeIcon color="#098B54" icon={game.icon} />

        </div>

        <div className="gameTitle">{game.title}</div>

        <div className="gameDescription">{game.description}</div>

      </div>
    );
  }
  const renderGameScreen = () => {

    const gameIntro = {backgroundColor:'rgba(0,0,0,0.8)',borderRadius:20,marginTop:20,padding:10,height: (window.innerHeight - 100)};

    if (props.gameState){

      if (props.gameState.status === 0){
        return(
          <div style={gameIntro}>

            {renderGameDetails()}

          </div>
        );
      }

      else if (props.gameState.status === 1){

        const putt = props.game.currentGame.puttData[props.gameState.puttCounter];

        return(

          <div style={{marginTop:20}}>

            <Tees width={screenWidth} tee={putt.tee} distance={putt.distance} />

            <Scoreboard game={props.game} gameState={props.gameState} />

          </div>
        );

      }

      else if (props.gameState.status === 2){

        return(

          <div>

            <h1 style={{color:'#fff'}}>GAME OVER</h1>

            <Scoreboard game={props.game} gameState={props.gameState} />

          </div>

        );

      }

    }

    else {

      return(
        <div style={gameIntro}>

          {renderGameDetails()}

        </div>
      );
    }


  }

  if (props.game){

    return(

      <div className="bg" style={{backgroundImage: "url(" + props.game.currentGame.bgImagePath + ")"}}>

        <div className="screen">

          <div style={{width: screenWidth, padding:20, alignItems:'center', justifyContent:'center'}}>

            {renderGameScreen()}

          </div>

          <div className="leaderboard" style={{height:window.innerHeight}}>

            <h2 style={{color:'#fff', padding:'10px'}}>LEADERBOARD</h2>

            <Leaderboard clubId={props.clubData.clubId} gameId={props.game.currentGame.id} />

          </div>

        </div>

      </div>
    );

  }

  return(

    <div>

      <h3>LOADING GAME</h3>

    </div>
  );
}
