import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

export default function StartForm(props) {

  const [clubId, setClubId] = useState('');

  const submitPressed = () => {
    console.log("club:", clubId);
    props.setClubId(clubId);
  }

  return(
    <Form>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>CLUB ID</Form.Label>
        <Form.Control type="text" placeholder="CLUB ID" value={clubId}
              onChange={e => setClubId(e.target.value)} />
      </Form.Group>

      <Button variant="success" type="submit" onClick={submitPressed}>
        SAVE
      </Button>
    </Form>

  );
}
