import Image from 'react-bootstrap/Image';
import './Leaderboard.css';

export default function LeaderRow(props) {

  const u = JSON.parse(props.leader.userName);

  const renderAvatar = (user) => {
    if (user.photoURL){
      return(
        <div className="avatar">
          <Image roundedCircle src={user.photoURL} width="50" height="50" alt="Photo" />
        </div>
      );
    }

    else {
      const i = user.displayName.substring(0,1);
      return(
        <div className="avatar"> {i} </div>
      );

    }
  }

  return(

    <div className="lbRow">

      <div className="lbPlace">{props.place}</div>

      {renderAvatar(u)}

      <div>

        <div className="lbDisplayName">{u.displayName}</div>

        <div className="lbHometown">{u.homeTown}</div>

      </div>

      <div className="lbScore">{props.leader.score}</div>

    </div>
  );
}
