import './HomeScreen.css';

export default function HomeScreen(props) {

  if (props.clubData){

    const bgStyle = {
      backgroundImage: "url(" + props.clubData.backgroundImage + ")",
      //backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'repeat',
      width:'100%',
      height:'100%',
      alignItems:'center',
      justifyContent:'center',
      position: 'absolute',
      left: 0,
      overflow: 'hidden'
    }

    return(

      <div style={bgStyle}>

        <div style={{position:'absolute', top:'50%', left:'50%', transform: 'translate(-50%, -50%)'}}>

          <div className="welcomeMessage">{props.clubData.welcomeMessage}</div>

          <div className="subText" style={{marginTop:'20px'}}>SELECT A GAME ON THE TOUCH SCREEN TO GET STARTED</div>

        </div>

      </div>
    );



  }

  return(

    <div>

      <h3>LOADING HOME</h3>

    </div>
  );
}
