import './Tees.css';

export default function Tees(props) {
  const w = props.width;
  const teeSize = (w/6);

  const teeContainer = {width:(w/3), height:150, alignItems:'center', justifyContent:'center', display:'flex'};
  let baseTee = {width: teeSize, height:teeSize, borderStyle:'solid', borderWidth:1, borderColor:'#fff', borderRadius:(teeSize / 2), marginLeft:10, marginRight:10 };
  let setTee = {backgroundColor:'#fff', width: teeSize, height:teeSize, borderStyle:'solid', borderWidth:1, borderColor:'#fff', borderRadius:(teeSize / 2), marginLeft:10, marginRight:10 };
  let tee1 = baseTee;
  let tee2 = baseTee;
  let tee3 = baseTee;

  if (props.tee == 1)
    tee1 = setTee;
  else if (props.tee == 2)
    tee2 = setTee;
  else if (props.tee == 3)
    tee3 = setTee;

  return(

    <div style={{alignItems:'center', justifyContent:'center'}}>

      <div className="teeRow">

        <div style={teeContainer}>
          <div style={tee1}></div>
        </div>
        <div style={teeContainer}>
          <div style={tee2}></div>
        </div>
        <div style={teeContainer}>
          <div style={tee3}></div>
        </div>

      </div>

      <div className="distance">{props.distance}</div>

    </div>
  );
}
