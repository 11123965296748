const remotePath = 'https://storage.googleapis.com/images.puttr.co/animations/';

const makeGifPaths = [
  { path: "make_1.gif" },
  { path: "make_3.gif" },
  { path: "make_4.gif" },
  { path: "make_5.gif" },
  { path: "make_6.gif" },
  { path: "make_7.gif" },
  { path: "make_8.gif" },
  { path: "make_10.gif" },
  { path: "make_11.gif" },
  { path: "make_13.gif" },
  { path: "make_14.gif" },
  { path: "make_15.gif" },
  { path: "make_16.gif" },
  { path: "make_17.gif" },
  { path: "make_18.gif" },
  { path: "make_19.gif" },
  { path: "make_20.gif" },
  { path: "make_21.gif" },
  { path: "make_22.gif" },
  { path: "make_23.gif" },
  { path: "make_24.gif" },
  { path: "make_25.gif" },
  { path: "make_26.gif" },
  { path: "make_27.gif" },
  { path: "make_28.gif" },
  { path: "make_29.gif" },
  { path: "make_30.gif" },
];
const missGifPaths = [
  { path: "miss_1.gif" },
  { path: "miss_2.gif" },
  { path: "miss_3.gif" },
  { path: "miss_4.gif" },
  { path: "miss_5.gif" },
  { path: "miss_6.gif" },
  { path: "miss_7.gif" },
  { path: "miss_8.gif" },
  { path: "miss_9.gif" },
  { path: "miss_10.gif" },
  { path: "miss_11.gif" },
  { path: "miss_12.gif" },
  { path: "miss_13.gif" },
  { path: "miss_14.gif" },
  { path: "miss_15.gif" },
  { path: "miss_16.gif" },
  { path: "miss_17.gif" },
  { path: "miss_18.gif" },
  { path: "miss_19.gif" },
  { path: "miss_20.gif" },
  { path: "miss_21.gif" },
  { path: "miss_22.gif" },
  { path: "miss_23.gif" },
  { path: "miss_24.gif" },
  { path: "miss_25.gif" },
  { path: "miss_26.gif" },
  { path: "miss_27.gif" },
  { path: "miss_28.gif" },
  { path: "miss_29.gif" },
  { path: "miss_30.gif" },
];


export const getMissGif = () => {
  const num = Math.floor(Math.random() * missGifPaths.length);
  return remotePath + missGifPaths[num].path;
}
export const getMakeGif = () => {
  const num = Math.floor(Math.random() * makeGifPaths.length);
  return remotePath + makeGifPaths[num].path;
}
